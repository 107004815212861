<script setup lang="ts">
import type { UpsertGroupedPhoto } from '~/types'

const props = defineProps<{ payload: UpsertGroupedPhoto }>()
defineEmits<{ (e: 'save', payload: UpsertGroupedPhoto): void }>()

const { cloned } = useCloned(props.payload)
</script>

<template>
  <TheDevOnlyNiceJson :value="cloned" />
  <FormKit v-model="cloned" type="form" :submit-label="$t('button.save')" @submit="$emit('save', cloned)">
    <FormKitFileDropzone
      name="file"
      validation="required|max:1"
      wrap-path-in-object
    />
  </FormKit>
</template>
