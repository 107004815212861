<script lang="ts" setup>
const { t: $t } = useI18n()

import type { UpsertGroupedPhoto } from '~/types'

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const { openGroupedPhotoPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const payload = computed((): UpsertGroupedPhoto | undefined => {
  if (!popupData.value) {
    return undefined
  }

  return { ...popupData.value, file: [] }
})

const upsert = useMutation({
  mutationFn: $trpc.groupedPhoto.upsert.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('query.error', { entity: $t('groupedPhoto.entity') }) }),
  onSuccess: async () => {
    await queryClient.resetQueries({ queryKey: ['groupedPhoto'] })
    closePopup()
    notification.success({ title: $t('query.success', { entity: $t('groupedPhoto.entity') }), duration: 4500 })
  },
})
</script>

<template>
  <ThePopup v-if="payload && popupData" :show="Boolean(payload)" :title="$t('groupedPhoto.popup.title')" @close="closePopup">
    <GroupedPhotoCreateOrEditForm :payload="payload" @save="p => upsert.mutate(p)" />
  </ThePopup>
</template>
